import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ nav }) => {
    console.log(window.innerWidth)
    const toggleHandle = () => {
        nav.isSidebar === '0' ? nav.setIsSidebar('100%') : nav.setIsSidebar('0')
    }
    return (
        <>
            <header className="header header-1">
                <div className="container">
                    <div className="header__wrapper">
                        <div className="header__logo">
                            <Link to='/'
                                onClick={(e) => toggleHandle()}
                            >
                                <img src="/assets/images/footer-logo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="header__nav" style={{ right: nav.isSidebar }} >
                            <ul className="header__nav-primary">
                                <li><Link to='/'><i className="fad fa-home-alt"></i></Link></li>
                                <li className="nav__dropdown dropdown-wrapper" id="menu-1">
                                    <Link to="/" className="nav__dropdown-info dropdown-info"
                                        onClick={(e) => toggleHandle()}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav__dropdown dropdown-wrapper" id="menu-2">
                                    <a className="nav__dropdown-info dropdown-info">
                                        ERP Solutions
                                    </a>
                                    <ul className="nav__dropdown-box dropdown-box">
                                        <li><Link to='/erp/window-covering'>Window Covering ERP</Link></li>
                                    </ul>
                                    {
                                        window.innerWidth <= 992 && (
                                            <>
                                                <Link to='/erp/window-covering' className=""
                                                    onClick={(e) => toggleHandle()}

                                                >
                                                    &emsp; - Window Covering ERP
                                                </Link>
                                            </>
                                        )
                                    }
                                </li>
                                <li className="nav__dropdown dropdown-wrapper" id="menu-1">
                                    <Link to="/contact" className="nav__dropdown-info dropdown-info"
                                        onClick={(e) => toggleHandle()}
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                            <span>
                                <button className='' onClick={toggleHandle}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </span>
                        </div>
                        <button onClick={toggleHandle} >
                            <div className="header__bars">
                                <div className="header__bars-bar header__bars-bar-1"></div>
                                <div className="header__bars-bar header__bars-bar-2"></div>
                                <div className="header__bars-bar header__bars-bar-3"></div>
                            </div>
                        </button>
                    </div>
                </div>
            </header >
        </>
    )
}

export default Sidebar