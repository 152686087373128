import { useState } from 'react';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import WindowCoveringERP from './pages/WindowCoveringERP';
import Contact from './pages/Contact';
import ContactThankyou from './pages/ContactThankyou';

function App() {
  const [isSidebar, setIsSidebar] = useState('header__nav');
  return (
    <BrowserRouter>

      <Routes>
        <Route path='/' element={<Home nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/contact' element={<Contact nav={{ isSidebar, setIsSidebar }} />} />
        <Route path='/erp/window-covering' element={<WindowCoveringERP nav={{ isSidebar, setIsSidebar }} />} />

        <Route path='/contact/thank-you' element={<ContactThankyou nav={{ isSidebar, setIsSidebar }} />} />
      </Routes>

    </BrowserRouter>

  );
}

export default App;
