import React from 'react'
import { Helmet } from "react-helmet";
import Sidebar from '../components/layout/Sidebar'
import Footer from '../components/layout/Footer'
import { useEffect } from 'react'

const WindowCoveringERP = ({ nav }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Window Covering ERP Solutions for manufacturers </title>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Odidor ERP solution software for window covering is designed to streamline your business operations, maximize efficiency, and boost profitability. Software offers automated window covering management, real-time production tracking, inventory management, CRM, customizable reporting. Whether you're a small business or a large enterprise, our ERP software is tailored to your specific needs, and we offer flexible pricing and scalable solutions." />
                <meta name="keywords" content="ERP software for window coverings,Window covering management software, Automated window covering software, Production tracking software for window coverings, Window covering inventory management,Sales order processing for window coverings, Customer relationship management for window coverings, Window covering manufacturing software, Window covering supply chain management software, Fabric and trim management software for window coverings, Window covering scheduling and planning software, Quality control software for window coverings,Window covering accounting software integration, Window covering e-commerce platform integration, Mobile window covering ERP software."
                />
                <meta name="author" content="Odidor ERP Solutions" />
                <link rel="canonical" href="https://odidor.app/erp/window-covering" />
                <meta name="subject" content="Window Covering ERP Software" />
                <meta name='Classification' content='Software as a Service' />
                <meta name='url' content='https://odidor.app/erp/window-covering' />
                <meta http-equiv='Pragma' content='no-cache' />
                <meta http-equiv='Cache-Control' content='no-cache' />
                <meta http-equiv='imagetoolbar' content='no' />
                <meta http-equiv='x-dns-prefetch-control' content='off' />

                <meta property="og:title" content="Window Covering ERP Solutions" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="https://odidor.app/erp/window-covering" />
                <meta property="og:image" content="https://odidor.app/assets/images/footer-logo.png" />
                <meta property="og:description" content="Odidor ERP solution software for window covering is designed to streamline your business operations, maximize efficiency, and boost profitability. Software offers automated window covering management, real-time production tracking, inventory management, CRM, customizable reporting." />
                <meta property="og:site_name" content="Odidor - ERP for Window covering" />
            </Helmet>
            <Sidebar nav={nav} />
            <section className="step" style={{ marginTop: "165px" }}>
                <div className="step__wrapper">
                    <div className="container">
                        <h2 className="section-heading color-black">Jumpstart your growth in just few clicks.</h2>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="step__box">
                                    <div className="image">
                                        <img src="/assets/images/window-covering/organize.png" alt="image" />
                                    </div>
                                    <div className="content">
                                        <h3>EASY TO<span>Organize.</span></h3>
                                        <p className="paragraph dark">
                                            Our ERP software is designed to streamline your business operations, maximize efficiency, and boost profitability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="step__box">
                                    <div className="image">
                                        <img src="/assets/images/window-covering/manage.png" alt="image" />
                                    </div>
                                    <div className="content">
                                        <h3>SIMPLE TO<span>Manage.</span></h3>
                                        <p className="paragraph dark">
                                            Our ERP offers automated window covering management, real-time production tracking, inventory management, CRM, and more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="step__box">
                                    <div className="image">
                                        <img src="/assets/images/window-covering/invoice.png" alt="image" />
                                    </div>
                                    <div className="content">
                                        <h3>FUN TO<span>Optimize.</span></h3>
                                        <p className="paragraph dark">
                                            Improve resource allocation, optimize scheduling and planning, and get comprehensive insights into your business performance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="button__wrapper">
                                <a href="#" className="button">
                                    <span>GET STARTED <i className="fad fa-long-arrow-right"></i></span>
                                </a>
                                <a href="#" className="button">
                                    <span>LEARN MORE <i className="fad fa-long-arrow-right"></i></span>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="step">
                <div className="step__wrapper" style={{ backgroundColor: "rgb(214 204 250 / 38%)" }}>
                    <div className="container px-3">
                        <h1>ERP Solution for Window Covering Manufacturing</h1>
                        <p class="paragraph dark">TOdidor presents a comprehensive ERP solution designed specifically for the window covering manufacturing industry. This solution integrates various business processes, ensuring streamlined operations, improved efficiency, and enhanced decision-making. The ERP system encompasses modules for production, inventory management, sales, finance, human resources, and customer relationship management (CRM), tailored to meet the unique needs of window covering manufacturers.</p>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default WindowCoveringERP