import React, { useEffect } from 'react'
import Sidebar from '../components/layout/Sidebar'
import { useState } from 'react';
import Footer from '../components/layout/Footer';

const Home = ({ nav }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Sidebar nav={nav} />
            {/*header section end*/}
            {/*hero section start*/}
            <section className="hero" >
                <div className="hero__wrapper">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6 order-2 order-lg-1 pt-5">
                                <h1 className="main-heading color-black">
                                    Progress on the fingertips!
                                </h1>
                                <p className="paragraph">
                                    <span>
                                        Odidor
                                    </span>{" "}
                                    helps to Manage all the prospects, customers, partners and sales management in a single place.
                                </p>
                                <div class="download-buttons">
                                    <a href="mailto:info@odidor.app" class="google-play">
                                        <i class="fab fa-google-play"></i>
                                        <div class="button-content">
                                            <h6>Get Started<span>Contact now</span></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="questions-img hero-img">
                                    <img src="./assets/images/phone-01.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*hero section end*/}
            {/*feature section start*/}
            <section className="feature" id="intro">
                <div className="container">
                    <h2 className="section-heading color-black">
                        Get surprised by amazing features.
                    </h2>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="feature__box feature__box--1">
                                <div className="icon icon-1">
                                    <i className="fad fa-user-astronaut" />
                                </div>
                                <div className="feature__box__wrapper">
                                    <div className="feature__box--content feature__box--content-1">
                                        <h3>Join in Easy Steps</h3>
                                        <p className="paragraph dark">
                                            Onboarding is much easier than ever, with our onboarding team you can integrate according to your preference
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature__box feature__box--2">
                                <div className="icon icon-2">
                                    <i className="fad fa-lightbulb-on" />
                                </div>
                                <div className="feature__box__wrapper">
                                    <div className="feature__box--content feature__box--content-2">
                                        <h3>Track Your Progress</h3>
                                        <p className="paragraph dark">
                                            With the real time compute and data base you can tract your data 360 in  real time update.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature__box feature__box--3">
                                <div className="icon icon-3">
                                    <i className="fad fa-solar-system" />
                                </div>
                                <div className="feature__box__wrapper">
                                    <div className="feature__box--content feature__box--content-3">
                                        <h3>Improve Your Growth</h3>
                                        <p className="paragraph dark">
                                            With out best backend admin panel you will get the data that you are looking for. And with our AI algorithms makes to create the best combination of data recommendation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="feature__box feature__box--4">
                                <div className="icon icon-4">
                                    <i className="fad fa-rocket-launch" />
                                </div>
                                <div className="feature__box__wrapper">
                                    <div className="feature__box--content feature__box--content-4">
                                        <h3>Become an Inspiration</h3>
                                        <p className="paragraph dark">
                                            For the better future of your company try it today and  we will integrate according to your needs and preference that drive your company better than before.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*feature section end*/}

            <>
                {/*growth section start*/}
                <section className="growth" id="feature">
                    <div className="growth__wrapper">
                        <div className="container">
                            <h2 className="section-heading color-black">
                                App that assists exponential growth.
                            </h2>
                            <p className="paragraph dark text-center"><b>Odidor ERP</b> (Enterprise Resource Planning) systems can bring significant benefits to organizations by streamlining operations and improving efficiency across various business processes. </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i className="fad fa-user-astronaut" />
                                        </div>
                                        <div className="content">
                                            <h3>Improved Workflow Efficiency</h3>
                                            <p className="paragraph dark">
                                                By automating routine tasks and standardizing business processes, Odidor ERP enhances workflow efficiency. This leads to quicker turnaround times and reduces the likelihood of human errors.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i className="fad fa-solar-system" />
                                        </div>
                                        <div className="content">
                                            <h3>Centralized Data Management</h3>
                                            <p className="paragraph dark">
                                                Odidor ERP integrates data from various departments into a single database, providing a unified source of truth. This centralization reduces data redundancy and ensures consistency across the organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i className="fad fa-lightbulb-on" />
                                        </div>
                                        <div className="content">
                                            <h3>Enhanced Reporting and Analytics</h3>
                                            <p className="paragraph dark">
                                                The system offers robust reporting and analytics tools that enable real-time insights into business performance. Users can generate detailed reports, track key performance indicators (KPIs), and make data-driven decisions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i className="fad fa-backpack" />
                                        </div>
                                        <div className="content">
                                            <h3>Inventory and Supply Chain Optimization</h3>
                                            <p className="paragraph dark">
                                                The ERP system helps manage inventory levels, stock alert, distribution, track shipments, and optimize supply chain operations. This results in reduced stockouts, lower carrying costs, and improved supplier relationships.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i className="fad fa-rocket-launch" />
                                        </div>
                                        <div className="content">
                                            <h3>Scalability and Flexibility</h3>
                                            <p className="paragraph dark">
                                                The ERP system is designed to scale with the growth of the business. It can be customized to meet the specific needs of different industries and adapt to changing business requirements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="growth__box">
                                        <div className="icon">
                                            <i class="fas fa-hand-holding-usd" />
                                        </div>
                                        <div className="content">
                                            <h3>Cost Reduction:</h3>
                                            <p className="paragraph dark">
                                                By optimizing business processes, reducing manual effort, and improving resource allocation, Odidor ERP can lead to significant cost savings. It helps businesses operate more efficiently and reduce overhead expenses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <p className="paragraph dark text-center mt-5">In summary, Odidor ERP enhances organizational efficiency, data accuracy, and decision-making capabilities, while also providing tools for better financial management, customer relationship management, and regulatory compliance.
                                </p>
                                <div className="button__wrapper">
                                    <a href="/contact" className="button" title='contact for custom ERP solutions'>
                                        <span>
                                            GET STARTED <i className="fad fa-long-arrow-right" />
                                        </span>
                                    </a>
                                    <a href="/erp/window-covering" className="button" title='learn more about the ERP solutions'>
                                        <span>
                                            LEARN MORE <i className="fad fa-long-arrow-right" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*growth section end*/}
            </>


            <Footer />

        </>

    )
}

export default Home