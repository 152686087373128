import React, { useEffect } from 'react'
import Sidebar from '../components/layout/Sidebar';
import Footer from '../components/layout/Footer';
import { useNavigate } from 'react-router-dom';

const Contact = ({ nav }) => {

    const navigate = useNavigate()

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "dd2da44b-07d5-4be4-94b1-746b7ad2fadf");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            navigate('/contact/thank-you')
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Sidebar nav={nav} />

            <section className="newsletter newsletter-2">
                <div className="newsletter__wrapper">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-8">
                                <div className="newsletter__info">
                                    <h2 className="section-heading color-black mt-5">
                                        Get in touch with us today.
                                    </h2>
                                    <form onSubmit={onSubmit}>
                                        <div className="comment_form">
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Name *"
                                                    className="input-field"
                                                    name="Name"
                                                    required
                                                />
                                                <input
                                                    type="email"
                                                    placeholder="Email *"
                                                    className="input-field"
                                                    name="eMail"
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    placeholder="Phone"
                                                    className="input-field"
                                                    name="Phone"
                                                />
                                            </div>
                                            <div>
                                                <textarea
                                                    placeholder="Write message *"
                                                    className="input-field"
                                                    defaultValue={""}
                                                    name="Message"
                                                    required
                                                />
                                                <button className="button" type="submit">
                                                    <span>
                                                        SEND <i className="fad fa-long-arrow-right" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="newsletter__img">
                                    <img src="assets/images/contact.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Contact