import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="footer__info">
                                <div className="footer__info--logo">
                                    <img src="/assets/images/footer-logo.png" alt="Odidor Logo " />
                                </div>
                                <div className="footer__info--content">
                                    <p className="paragraph dark">
                                        <b>Odidor</b> helps to Manage all the prospects, customers, partners and sales management in a single place.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="footer__content-wrapper">
                                <div class="footer__list">
                                    <ul>
                                        <li>Explore</li>
                                        <li><Link to="/">Home</Link></li>
                                    </ul>
                                </div>
                                <div class="footer__list">
                                    <ul>
                                        <li>ERP Solutions</li>
                                        <li><Link to="/erp/window-covering">Window Covering</Link></li>
                                    </ul>
                                </div>
                                <div class="footer__list">
                                    <ul>
                                        <li>Help</li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                                <div class="download-buttons">
                                    <Link to="/contact" class="google-play">
                                        <i class="fab fa-google-play"></i>
                                        <div class="button-content">
                                            <h6>Get Started<span>Contact now</span></h6>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer