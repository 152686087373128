import React from 'react'
import Sidebar from '../components/layout/Sidebar'
import Footer from '../components/layout/Footer'

const ContactThankyou = ({ nav }) => {
    return (
        <>
            <Sidebar nav={nav} />
            <section className="newsletter newsletter-2">
                <div className="newsletter__wrapper">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-8">
                                <div className="newsletter__info">
                                    <h5 className="section-heading color-black mt-5">
                                        We will get back to you soon 🤓
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="newsletter__img">
                                    <img src="/assets/images/thank-you.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default ContactThankyou